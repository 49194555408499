export const routes = [
    {
        path: '/dues',
        name: 'dues.browse',
        component: () => import(/* webpackChunkName: "DuesBrowse" */ '@/views/app/DuesBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/dues/create',
        name: 'dues.create',
        component: () => import(/* webpackChunkName: "DuesActions" */ '@/views/app/DuesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/dues/:id/edit',
        name: 'dues.edit',
        component: () => import(/* webpackChunkName: "DuesActions" */ '@/views/app/DuesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/dues/:id/delete',
        name: 'dues.delete',
        component: () => import(/* webpackChunkName: "DuesActions" */ '@/views/app/DuesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]